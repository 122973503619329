import React, { Component } from 'react'

import {
  BrowserRouter,
} from 'react-router-dom'

import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { Helmet } from 'react-helmet' 
import favicon from './assets/favicon.png'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react'

import {
  MainAppContainer,
} from './shared/components'
import './shared/styles/main.scss'
import reducer from './features/reducer'

import 'antd/dist/antd.css'

const persistConfig = {
  key: 'root',
  storage,
}

const history = createBrowserHistory()
const createMiddleware = applyMiddleware(thunk)(createStore)
const persistedReducer = persistReducer(persistConfig, reducer)

const store = createMiddleware(persistedReducer)
const persistor = persistStore(store)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter history={history}>
              <Helmet>
                  <meta charSet="utf-8" />
                  <title>Bitreel CMS</title>
                  <link rel="shortcut icon" id="favicon" href={favicon}></link>
              </Helmet>
              <MainAppContainer />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    )
  }
}

export default App;
export {store}
export {history}