import React, { Component } from 'react'
import qs from 'query-string' 
import { withRouter } from "react-router"
import { setToken, getMe, setRefresh, refreshToken } from '../../features/auth/authActions'
import { getAllUsers } from '../../features/users/usersActions'
import { getAllCompanies, updateCompanyRequest, getAllStatuses, setLoginUrlCache } from '../../features/companies/companiesActions'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { ContentArea, Navbar } from '../components'
import { NO_AUTH_URLS } from '../constants'
import _ from 'lodash'
import AwesomeDebouncePromise from 'awesome-debounce-promise';

const { Header, Content } = Layout

function isNumeric(value) {
  return /^\d+$/.test(value);
}
class MainAppContainer extends Component {

  handleMouseClick = () => {
    if (this.props) {
      const { refresh, token } = this.props.auth

      if (window.location.origin == 'http://localhost:3000') { // this makes my life much easier in development, and the token is somewhat public anyways
        // console.log(token)
      }

      if (refresh && token) {
        this.props.refreshToken(token, refresh)
      }
    }
  }

  

  componentDidMount() {
    window.addEventListener("click", AwesomeDebouncePromise(this.handleMouseClick, 500))
    const { getMe, setToken, setRefresh, getAllUsers, getAllCompanies, getAllStatuses,
      auth, companies, users, location, history, setLoginUrlCache } = this.props

    const { access, refresh, item, company } = qs.parse(location.search)

    if (item !== undefined && company !== undefined) {
      setLoginUrlCache({item: item, company: company});
    }

    if (access) {
      setToken(access)
      setRefresh(refresh)
      getMe(access)
      getAllUsers(access)
      getAllCompanies(access)
      getAllStatuses(access)

      const itemId = localStorage.getItem('itemId');
      const companyId = localStorage.getItem('companyId');

      if (isNumeric(itemId) && isNumeric(companyId)) 
      {
        history.push(`/model_info?item=${itemId}&company=${companyId}`);
        setLoginUrlCache(undefined);
        localStorage.setItem('itemId', null); // 'null'
        localStorage.setItem('companyId', null);
      } else {
        history.location.pathname = window.location.origin;
      }
    } else if (! auth.token && ! NO_AUTH_URLS.includes(window.location.pathname)) {
      // next line is commented due to new error handling and redirects.
      // window.location.href = window.location.origin
    } 
    
    if (auth.token) {
      getMe(auth.token)
      if ((_.isEmpty(companies) || _.isEmpty(users))) {
        getAllUsers(auth.token)
        getAllCompanies(auth.token)
        getAllStatuses(auth.token)
      }
    }
  }

    render() {
        const {companies, users, auth} = this.props
        const { token } = auth
        
        return <>
            <Layout className="layout">
            { token ?
              <Header style={{
                background: '#F5F5F5',
                height: 64
              }}>
                <Navbar />
              </Header>
              : null
            }
              <Content style={{ padding: '0 0px' }}>
                <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
                    <ContentArea auth={auth} users={users.users ? Object.values(users.users) : null} companies={ companies.companies ? Object.values(companies.companies) : null} />
                </div>
              </Content>
            </Layout>          
        </>;
    }
}

const mapStateToProps = ({ auth, users, companies, }) => {
    return { 
        auth,
        users,
        companies,
    }
}

export default connect (mapStateToProps, {
    setToken,
    getMe,
    getAllUsers,
    getAllCompanies,
    updateCompanyRequest,
    getAllStatuses,
    setRefresh,
    refreshToken,
    setLoginUrlCache
}) (withRouter(MainAppContainer))