export const BACKEND_URL = window.location.hostname === 'localhost'
    ? 'http://localhost:5000/api' 
    : window.location.hostname === 'cms-dev.bitreel.com'
    ? 'https://api-dev.bitreel.com/api'
    : 'https://api.bitreel.com/api'

export const HOME_URL = '/'
export const MODEL_SELECT_URL = '/model_select'
export const MODEL_INFO_URL = '/model_info'
export const QR_VIEW_URL = '/view_qr'

export const NO_AUTH_URLS = [HOME_URL, QR_VIEW_URL]

export const LOGIN_ENDPOINT = `${BACKEND_URL}/oauth`
export const LOGOUT_ENDPOINT = `${BACKEND_URL}/logout`

export const ERROR_COLOR = '#FFFFFF'
