import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Avatar } from 'antd'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { setToken } from '../../features/auth/authActions'
import { setCompanyDetail } from '../../features/companies/companiesActions'
import { connect } from 'react-redux'
import Logo from '../../assets/logo.png'
import { HOME_URL } from '../constants';

class Navbar extends Component {
    render() {
      const { setToken, history, setCompanyDetail } = this.props
        return <>
          <div className='top-bar'>
          <img className='nav-logo' alt='br-logo' src={Logo} onClick={() => history.push(HOME_URL)}/>
            <Button onClick={ () => {
                setToken(null)
                setCompanyDetail(null)
                history.push(HOME_URL)
            }}
             style={{marginRight: 10}} size='small' icon={<LogoutOutlined />} type='primary'>Sign Out</Button>
            <Avatar style={{marginRight: 5}} size="small" icon={<UserOutlined />} /><span className='top-bar-username'>{this.props.auth.user ? this.props.auth.user.email : 'Username'}</span>
          </div>
        </>
    }
}

const mapStateToProps = ({ auth}) => {
  return { 
    auth
  }
}

export default connect (mapStateToProps, {
  setToken,
  setCompanyDetail,
}) (withRouter(Navbar))