import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { Spin, Typography, Table, Button, Row, Col, Modal, Form, Input, Select, Upload, Image, Space, notification } from 'antd'
import { SideMenu } from '../shared/components'
import { setOrder, createProject, createOrder, setItem, createItem, editItem, uploadFileLocker, getFileLockerRequest, getItemRequest, getThumbnailRequest, editThumbnailRequest, createThumbnailRequest } from '../features/companies/companiesActions'
import _ from 'lodash'
import { MODEL_INFO_URL, BACKEND_URL } from '../shared/constants'
import { PlusOutlined, CloseOutlined, InboxOutlined, RightOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons'
import moment from 'moment'
import Highlighter from 'react-highlight-words';

const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

class ModelSelect extends Component {
    state = {
        loading: false,
        showOrderModal: false,
        showProjectModal: false,
        showItemModal: false,
        showEditModal: false,
        searchText: '',
        searchedColumn: '',
        editItemRecord: null,
    }
    projectFormRef = React.createRef();
    orderFormRef = React.createRef();
    itemFormRef = React.createRef();

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
            <Input
                ref={node => {
                this.searchInput = node;
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
                >
                Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
                </Button>
            </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
            ) : (
            text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    onFinishFailed = () => {
        console.log('Bad input!')
    }

    onProjectFinish = () => {
        const { createProject, token, companies } = this.props
        const { companyDetail } = companies
        const data = this.projectFormRef.current.getFieldsValue()
        data['type_id'] = 1 // TODO: when we have many types, get rid of this hardcoded value
        data['company_id'] = companyDetail.id
        createProject(token, data)
        this.setState({showProjectModal: false})
    }

    onOrderFinish = () => {
        const { createOrder, token } = this.props
        const data = this.orderFormRef.current.getFieldsValue()
        createOrder(token, data)
        this.setState({showOrderModal: false})
    }

    onItemFinish = () => {
        const { createItem, token, uploadFileLocker, createThumbnailRequest } = this.props
        const data = this.itemFormRef.current.getFieldsValue()
        const reader = new FileReader()

        reader.onload = e => {
            const thumbnailData =  e.target.result
            data.order_id = this.props.companies.orderData.id
            let productImages = []
            let referenceFiles = []

            if (data.product_image) {
                productImages = data.product_image.fileList
            }
            if (data.reference_file) {
                referenceFiles = data.reference_file.fileList
            }

            delete data.product_image
            delete data.reference_file
            delete data.thumbnail

            createItem(token, data).then( resp => {
                createThumbnailRequest(token, {
                    item_id: resp.id,
                    thumbnail: thumbnailData
                })
                productImages.forEach( productImage => {
                    uploadFileLocker(token, resp.id, productImage.originFileObj, 'product_image', productImage.name)
                })
                referenceFiles.forEach( referenceFile => {
                    uploadFileLocker(token, resp.id, referenceFile.originFileObj, 'reference_file', referenceFile.name)
                })
            })
        };
        if (! _.isNil(data.thumbnail) && ! _.isNil(data.thumbnail.file) && ! _.isNil(data.description)){
            reader.readAsDataURL(data.thumbnail.file)
            this.setState({showItemModal: false})
        } else {
            notification.error({
                message: 'Bad Input',
                description:
                'Must include a thumbnail and description!',
              })
        }
    }
    onItemEditFinish = () => {
        const { editItem, token, editThumbnailRequest, createThumbnailRequest, setItem } = this.props
        const { editItemRecord } = this.state
        const data = this.itemFormRef.current.getFieldsValue()
        const reader = new FileReader();

        reader.onload = e => {
            const thumbnailData = e.target.result
            this.setState({loading: true}, () => {
                delete data.thumbnail
                editItem(token, data).then(() => {
                    const req = editItemRecord.thumbnail_id ? editThumbnailRequest(token, editItemRecord.thumbnail_id, {
                        item_id: editItemRecord.id,
                        thumbnail: thumbnailData
                    }) : createThumbnailRequest(token, {
                        item_id: editItemRecord.id,
                        thumbnail: thumbnailData
                    })
                    req.then(() => {
                        this.setState({loading: false})
                    })
                })
            })
        };
        data.id = editItemRecord.id

        if (data.thumbnail && typeof(data.thumbnail) !== 'string') {
            reader.readAsDataURL(data.thumbnail.file);
        } else {
            this.setState({loading: true}, () => {
                editItem(token, data).then(() => {
                    this.setState({loading: false})
                })
            })
        }
        this.setState({showEditModal: false})
    }

    getProjectOptions(projects) {
        const { Option } = Select

        const options = []
        projects.forEach(project => {
            options.push(<Option key={project.id}>{project.name}</Option>)
        })
        return options
    }

    getOrderOptions(orders) {
        const { Option } = Select

        const options = []
        orders.forEach(order => {
            options.push(<Option key={order.id}>{order.name}</Option>)
        })
        return options
    }

    getItemForm(onFinish, formName='createItem', showLocker=true) {
        const { Dragger } = Upload
        const { editItemRecord } = this.state
        const { token, getThumbnailRequest } = this.props
        const thumbnailData = editItemRecord && editItemRecord.thumbnail_id ? getThumbnailRequest(token, editItemRecord.thumbnail_id) : null
        return <Form
            {...formLayout}
            name={formName}
            onFinish={onFinish}
            onFinishFailed={this.onItemFailed}
            ref={this.itemFormRef}
            initialValues={ ! editItemRecord ? {} : {
                name: editItemRecord.name,
                price: editItemRecord.price,
                description: editItemRecord.description,
                product_url: editItemRecord.product_url,
                thumbnail: thumbnailData ? thumbnailData.thumbnail: '',
            }}
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input item name - unique per order!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: 'Please input item price!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: 'Please input item description!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Product URL"
                name="product_url"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Thumbnail"
                name="thumbnail"
                rules={[{ required: true, message: 'Please upload thumbnail for image as PNG' }]}
                valuePropName="file"
            >
                <Dragger name='file' multiple={true}  accept=".png,.jpg"
                    beforeUpload={file => {
                    return false
                }}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                    </p>
                </Dragger>
            </Form.Item>
            {showLocker ?
            <>
                <Form.Item
                    label="Product Images"
                    name="product_image"
                    rules={[{ required: false, message: 'Please upload any additional product images' }]}
                    valuePropName="file"
                >
                    <Dragger name='file' multiple={true}
                        beforeUpload={file => {
                        return false
                    }}>
                        <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                        Support for a single or bulk upload.
                        </p>
                    </Dragger>
                </Form.Item>
                <Form.Item
                    label="Reference Files"
                    name="reference_file"
                    rules={[{ required: false, message: 'Please upload any 3D reference files' }]}
                    valuePropName="file"
                >
                    <Dragger name='file' multiple={true}
                        beforeUpload={file => {
                        return false
                    }}>
                        <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                        Support for a single or bulk upload.
                        </p>
                    </Dragger>
                </Form.Item>
            </>
            : null}
        </Form>
    }

    mouseDownHandler = ( event, record ) => {
        if( event.button === 1 || event.button === 2 ) {
            const { setItem, token, getFileLockerRequest } = this.props
            setItem(record)
            getFileLockerRequest(token, record.id).then(
                window.open(MODEL_INFO_URL, '_blank')
            )
        }
      }


    render() {
        const { companies, setOrder, user, getFileLockerRequest, token, getItemRequest } = this.props
        const { Title } = Typography
        const { showOrderModal, showProjectModal, showItemModal, showEditModal, loading } = this.state

        if (! companies.companyDetail || loading) {
            return <Spin />
        }
        const { companyDetail, orderData, itemStatuses } = companies
        const { projects, name } = companyDetail
        const projectIds = _.map(projects, 'id')

        if( orderData && ! projectIds.includes(orderData.project_id)) {
            setOrder(null)
        }

        const columns = [
            {
                title: 'Thumbnail',
                dataIndex: 'thumbnail',
                key: 'thumbnail',
                render: (text, record) => {
                        return <Image preview={false} width={120} src={record.thumbnail_url ? `${BACKEND_URL}/${record.thumbnail_url}` : ''} />
                }
            },
            {
                title: 'Model Name',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Status',
                dataIndex: 'current_status',
                key: 'current_status',
                filters: _.map(Object.values(itemStatuses), (obj => {
                    return {
                        text: obj.name,
                        value: obj.name
                    }
                })
                ),
                  onFilter: (value, record) => {
                    return record.current_status === value
                  },
            },
            {
                title: 'Updated',
                dataIndex: 'updated',
                key: 'updated',
                render: (text, record ) => {
                    return `${moment(text).format('YYYY-MM-DD HH:mm:ss')} GMT`
                },
                sorter: (a, b) => {
                    return moment(a.updated) - moment(b.updated)
                }
            },
            {
                title: 'View',
                key: 'id',
                render: (text, record ) => {
                    return <Button onContextMenu={(e)=>e.preventDefault()} onMouseDown={(e) => {this.mouseDownHandler(e, record)}} onClick={(e) => {
                        e.stopPropagation();
                        const { history } = this.props
                        getItemRequest(token, record.id).then (() =>{
                            getFileLockerRequest(token, record.id).then(() => {
                                history.push(MODEL_INFO_URL)
                            })
                        })
                    }
                    }
                    icon={<RightOutlined />}
                    >Go</Button>
                },
            },
        ]
        return <>
            <Row><Title level={4}>{`${name}: ${orderData ? orderData.project_name : 'No Projects'}, ${ orderData ? orderData.name : 'No Orders'}`}</Title></Row>
            <Row style={user.admin ? {marginBottom: 15} : {display:'none'}}>
                <Button icon={<PlusOutlined />} onClick={() => this.setState({showProjectModal: true})}>Create Project</Button>
                <Button style={{marginLeft: 5}} icon={<PlusOutlined />} onClick={() => this.setState({showOrderModal: true})}>Create Order</Button>
            </Row>
            <Row>
                <Col span={6}><SideMenu projects={projects}/></Col>
                <Col span={18}>
                    <Button style={{marginBottom: 15}} disabled={! Boolean(orderData)} icon={<PlusOutlined />} onClick={() => this.setState({showItemModal: true})}>Create Item</Button>
                    <Table
                        dataSource={_.map(orderData ? orderData.items : {}, data => data)}
                        columns={columns}
                        rowKey='id'
                        onRow={(record, rowIndex) => {
                            return {
                            onClick: event => {this.setState({editItemRecord: record, showEditModal: true})}, // click row
                            onContextMenu: event => {}, // right button click row
                            };
                        }}
                /></Col>
            </Row>
            <Modal
                title={'Create Project'}
                visible={ showProjectModal }
                onOk={this.onProjectFinish}
                onCancel={() => this.setState({showProjectModal: false})}
                destroyOnClose={true}
                cancelText='Cancel'
                okButtonProps={{ icon: <PlusOutlined /> }}
                cancelButtonProps={{ icon: <CloseOutlined /> }}
                okText='Create'
            >
                <Form
                    {...formLayout}
                    name="project"
                    onFinish={this.onProjectFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.projectFormRef}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input project name - unique per company!' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Create Order'}
                visible={ showOrderModal }
                onOk={this.onOrderFinish}
                onCancel={() => this.setState({showOrderModal: false})}
                destroyOnClose={true}
                cancelText='Cancel'
                okButtonProps={{ icon: <PlusOutlined />}}
                cancelButtonProps={{ icon: <CloseOutlined /> }}
                okText='Create'
            >
                <Form
                    {...formLayout}
                    name="order"
                    onFinish={this.onOrderFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.orderFormRef}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input order name - unique per project!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Project"
                        name="project_id"
                        rules={[{ required: true, message: 'Please select project!' }]}
                    >
                        <Select>
                            {this.getProjectOptions(projects)}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Create Item'}
                visible={ showItemModal }
                onOk={this.onItemFinish}
                onCancel={() => this.setState({showItemModal: false})}
                destroyOnClose={true}
                cancelText='Cancel'
                okButtonProps={{ icon: <PlusOutlined />}}
                cancelButtonProps={{ icon: <CloseOutlined /> }}
                okText='Create'
            >
                {this.getItemForm(this.onItemFinish)}
            </Modal>
            <Modal
                title={'Edit Item'}
                visible={ showEditModal }
                onOk={this.onItemEditFinish}
                onCancel={() => this.setState({showEditModal: false})}
                destroyOnClose={true}
                cancelText='Cancel'
                okButtonProps={{ icon: <EditOutlined />}}
                cancelButtonProps={{ icon: <CloseOutlined /> }}
                cancelText='Close'
                okText='Edit'
            >
                {this.getItemForm(this.onItemEditFinish, 'editItem', false)}
            </Modal>
        </>
        }
    }

    const mapStateToProps = ({  companies }) => {
        return {
            companies,
        }
    }

    export default connect (mapStateToProps, {
        setOrder,
        createProject,
        createOrder,
        setItem,
        createItem,
        editItem,
        uploadFileLocker,
        getFileLockerRequest,
        getItemRequest,
        getThumbnailRequest,
        editThumbnailRequest,
        createThumbnailRequest,
    }) (withRouter(ModelSelect))
