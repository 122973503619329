export const SET_COMPANIES = 'SET_COMPANIES'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const SET_COMPANY_DETAIL = 'SET_COMPANY_DETAIL'
export const SET_ORDER = 'SET_ORDER'
export const CREATE_ORDER = 'CREATE_ORDER'
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const CREATE_COMPANY = 'CREATE_COMPANY'

export const SET_ITEM = 'SET_ITEM'
export const SET_STATUSES = 'SET_STATUSES'
export const SET_FILE_LOCKER = 'SET_FILE_LOCKER'

export const SET_ACTIVE_COMMENT_ID = 'SET_ACTIVE_COMMENT_ID'

export const SET_HOTSPOTS = 'SET_HOTSPOTS'
export const ADD_HOTSPOT = 'ADD_HOTSPOT'
export const REMOVE_HOTSPOT = 'REMOVE_HOTSPOT'
export const SELECT_HOTSPOT = 'SELECT_HOTSPOT'

export const SET_LOGIN_URL_CACHE = 'SET_LOGIN_URL_CACHE'