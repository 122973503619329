import React, { Component } from 'react'
import { withRouter } from "react-router"
import { Radio, Typography, Col, Row, Button } from 'antd'
import _ from 'lodash'
import { MODEL_SELECT_URL } from '../shared/constants'
import { connect } from 'react-redux'
import { 
    getCompanyRequest,
} from '../features/companies/companiesActions'

const JUSTIFY = 'center'
const BUTTON_TYPE = 'primary'
const BUTTON_WIDTH = '100%'

class ClientCompany extends Component {
    state = {
        selectedCompany: null,
    }

    handleCompanyChange = (selectedCompany) => {
        this.setState({selectedCompany})
    }

    componentDidMount() { 
        const { selectedCompany } = this.state
        if (! selectedCompany && ! _.isEmpty(this.props.companies)) {
            this.setState({selectedCompany: this.props.companies[0].name})
        }
    }

    getCompanies() {
        const companies = []
        const { selectedCompany } = this.state

        if (! _.isEmpty(this.props.companies)) {
            Object.values(this.props.companies).forEach(company => {
                companies.push(<Radio.Button key={company.name} value={company.name}>{company.name}</Radio.Button>)
            })
        }
        return <>
            <Radio.Group onChange={(e) => this.setState({selectedCompany: e.target.value})} value={selectedCompany} size='large' buttonStyle='solid'>
                {companies}
            </Radio.Group>
        </>
    }

    getCurrentCompanyId() { // company name is unique in db
        const { selectedCompany } = this.state
        return _.filter(this.props.companies, obj => obj.name === selectedCompany)[0].id
    }

    registerStripe = () => {
        console.log(`get stripe for company id: ${this.getCurrentCompanyId()}`)
    }

    registerTaxJar = () => {
        console.log(`get taxjar for company id: ${this.getCurrentCompanyId()}`)    
    }

    showModelSelectPage = () => {
        const {getCompanyRequest, token} = this.props
        getCompanyRequest(token, this.getCurrentCompanyId())
        this.props.history.push(MODEL_SELECT_URL)
    }

    render() {
        const { Title, Paragraph } = Typography;
        const { selectedCompany } = this.state

        return <>
            <div className='client-company-selector'>
                <Row><div className='client-company-top-space'/></Row>
                <Row justify={JUSTIFY}> <Title level={4}>Companies</Title> </Row>
                { selectedCompany ? 
                <>
                <Row justify={JUSTIFY}> {this.getCompanies()} </Row>
                <Row> <div className='client-company-title-space'/> </Row>
                <Row> <div className='client-company-bottom-space'/> </Row>
                <Row justify={JUSTIFY}>
                    <Col span={6}>
                        <Button type={BUTTON_TYPE} style={{width: BUTTON_WIDTH}} onClick={ () => this.showModelSelectPage()}>
                            {`${selectedCompany} Projects >`}
                        </Button>
                    </Col>
                </Row>
                </>
                 :
                <Paragraph>Not added to any companies yet!</Paragraph>
    }
            </div>
        </>
    }
}

const mapStateToProps = () => {
    return { 
    }
}

export default connect (mapStateToProps, {
    getCompanyRequest,
}) (withRouter(ClientCompany))