import authReducer from './auth/authReducer'
import companiesReducer from './companies/companiesReducer'
import usersReducer from './users/usersReducer'

import { combineReducers } from 'redux'

export default combineReducers ({
    auth: authReducer,
    users: usersReducer,
    companies: companiesReducer
})