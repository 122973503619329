import {
    SET_COMPANIES,
    UPDATE_COMPANY,
    SET_COMPANY_DETAIL,
    SET_ORDER,
    CREATE_ORDER,
    CREATE_PROJECT,
    CREATE_COMPANY,
    SET_ITEM,
    SET_STATUSES,
    SET_FILE_LOCKER,
    SET_ACTIVE_COMMENT_ID,
    SET_HOTSPOTS,
    ADD_HOTSPOT,
    REMOVE_HOTSPOT,
    SELECT_HOTSPOT,
    SET_LOGIN_URL_CACHE
} from './companiesConstants'

import ApiService from '../apiService'
import { updateUser } from '../users/usersActions'
import _ from 'lodash'

export const setFileLocker = (fileLockerData) => {
    return {
        type: SET_FILE_LOCKER,
        fileLockerData,
    }
}

export const setActiveCommentId = (activeCommentId) => {
    return {
        type: SET_ACTIVE_COMMENT_ID,
        activeCommentId,
    }
}

export const setHotSpots = (allHotSpots) => {
    return {
        type: SET_HOTSPOTS,
        allHotSpots,
    }
}

export const selectHotSpotHandler = (hotspot) =>  async dispatch =>{
    const viewer = document.getElementById('threeDModel');
    for (let i = 0; i < viewer.children.length; i++) {
        viewer.children[i].classList.remove('selected');
    }
    hotspot.classList.add('selected');
    dispatch(selectHotSpot(hotspot))
}

export const addHotSpot = (newHotSpot, index, onClickCallback, hotspotClass) => {
    const {pX, pY, pZ, nX, nY, nZ, } = newHotSpot
    let domHotSpot = document.createElement('button');

    domHotSpot.slot = `hotspot-${index}`;
    domHotSpot.classList.add(hotspotClass);

    domHotSpot.dataset.position = `${pX} ${pY} ${pZ}`;
    domHotSpot.dataset.normal = `${nX} ${nY} ${nZ}`;
    domHotSpot.addEventListener('click', () => {
        onClickCallback(domHotSpot)
    });

    return {
        type: ADD_HOTSPOT,
        domHotSpot,
    }
}

export const removeHotSpot = (oldHotSpot) => {
    return {
        type: REMOVE_HOTSPOT,
        oldHotSpot,
    }
}

export const selectHotSpot = (selectHotSpot) => {
    return {
        type: SELECT_HOTSPOT,
        selectHotSpot,
    }

}

export const updateCompany = (companyId, companyData) => {
    return {
        type: UPDATE_COMPANY,
        companyId,
        companyData,
    }
}

export const createNewCompany = (newCompanyData) => {
    return {
        type: CREATE_COMPANY,
        newCompanyData,
    }
}

export const setStatuses = (statuses) => {
    return {
        type: SET_STATUSES,
        statuses,
    }
}

export const setCompanies = (companies) => {
    return {
        type: SET_COMPANIES,
        companies,
    }
}

export const setCompanyDetail = (companyDetail) => {
    return {
        type: SET_COMPANY_DETAIL,
        companyDetail,
    }
}

export const setOrder = (orderData) => {
    return {
        type: SET_ORDER,
        orderData,
    }
}

export const setItem = (itemData) => {
    return {
        type: SET_ITEM,
        itemData,
    }
}

export const createNewOrder = (newOrderData) => {
    return {
        type: CREATE_ORDER,
        newOrderData,
    }
}

export const createNewProject = (newProjectData) => {
    return {
        type: CREATE_PROJECT,
        newProjectData,
    }
}

export const createCompany = (token, companyData) =>  async dispatch => {
    try {
        const response = await ApiService.postCompany(token, companyData)
        const { data } = response
        dispatch(createNewCompany(data))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const createOrder = (token, orderData) =>  async dispatch =>{
    try {
        const response = await ApiService.postOrder(token, orderData)
        const { data } = response
        dispatch(createNewOrder(data))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const createProject = (token, projectData) => async dispatch => {
    try {
        const response = await ApiService.postProject(token, projectData)
        const { data } = response
        dispatch(createNewProject(data))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const getAllCompanies = (token) => async dispatch => {
    try {
        const response = await ApiService.getCompanies(token)
        const { data } = response
        const companies = {}

        data.forEach(element => {
            companies[element.id] = element
        })

        dispatch(setCompanies(companies))
        return companies

    } catch(error) {
        console.warn(error)
    }
}

export const getAllStatuses = (token) => async dispatch => {
    try {
        const response = await ApiService.getItemStatuses(token)
        const { data } = response
        const statuses = {}

        data.forEach(element => {
            statuses[element.id] = element
        })

        dispatch(setStatuses(statuses))
        return statuses

    } catch(error) {
        console.warn(error)
    }
}

export const getCompanyRequest = (token, companyId) => async dispatch => {
    try {
        const response = await ApiService.getCompany(token, companyId)
        const { data } = response
        dispatch(setCompanyDetail(data))

        if (data.projects.length && data.projects[0].orders.length) {
            dispatch(setOrder(data.projects[0].orders[0]))
        }

        return data

    } catch(error) {
        console.warn(error)
    }
}

export const updateCompanyRequest = (token, companyId, companyData) => async dispatch => {
    try {
        const response = await ApiService.patchCompany(token, companyId, companyData)
        const { data } = response
        dispatch(updateCompany(companyId, data))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const addUserToCompany = (token, userId, companyId) => async dispatch => {
    try {
        const response = await ApiService.postUserCompany(token, userId, companyId)
        const { data } = response
        const { user, company } = data

        dispatch(updateCompany(company.id, company))
        dispatch(updateUser(user.id, user))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const removeUserFromCompany = (token, userId, companyId) => async dispatch => {
    try {
        const response = await ApiService.deleteUserCompany(token, userId, companyId)
        const { data } = response
        const { user, company } = data

        dispatch(updateCompany(company.id, company))
        dispatch(updateUser(user.id, user))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const updateItem = (token, itemId, itemData) => async dispatch => {
    try {
        const response = await ApiService.patchItem(token, itemId, itemData)
        const { data } = response
        dispatch(getCompanyRequest(token, data.order.project.company_id))
        dispatch(setItem(data))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const updateItemVersion = (token, itemVersionId, itemVersionData) => async dispatch => {
    try {
        const response = await ApiService.patchItemVersion(token, itemVersionId, itemVersionData)
        const { data } = response
        dispatch(getCompanyRequest(token, data.item.order.project.company_id))
        dispatch(getItemRequest(token, data.item.id))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const createItem = (token, itemData) => async dispatch => {
    try {
        const response = await ApiService.postItem(token, itemData)
        const { data } = response
        dispatch(getCompanyRequest(token, data.order.project.company_id))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const editItem = (token, itemData) => async dispatch => {
    try {
        const response = await ApiService.patchItem(token, itemData)
        const { data } = response
        dispatch(getCompanyRequest(token, data.order.project.company_id))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const deleteItem = (token, itemId) => async dispatch => {
    try {
        const response = await ApiService.deleteItem(token, itemId)
        const { data } = response
        dispatch(getCompanyRequest(token, data.company_id))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const createItemVersion = (token, itemVersionData) => async dispatch => {
    try {
        const response = await ApiService.postItemVersion(token, itemVersionData)
        const { data } = response
        dispatch(getCompanyRequest(token, data.item.order.project.company_id))
        dispatch(getItemRequest(token, data.item.id))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const createItemNote = (token, itemNoteData) => async dispatch => {
    try {
        const response = await ApiService.postItemNote(token, itemNoteData)
        const { data } = response
        dispatch(getCompanyRequest(token, data.item_version.item.order.project.company_id))
        dispatch(getItemRequest(token, data.item_version.item.id))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const createAnnotation = (token, annotationData) => async dispatch => {
    try {
        const response = await ApiService.postAnnotation(token, annotationData)
        const { data } = response
        dispatch(getCompanyRequest(token, data.item_note.item_version.item.order.project.company_id))
        dispatch(getItemRequest(token, data.item_note.item_version.item.id))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const getItemRequest = (token, itemId) => async dispatch => {
    try {
        const response = await ApiService.getItem(token, itemId)
        const { data } = response
        dispatch(setItem(data))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const deleteFileLocker =(token, itemId, fileType, fileName) => async dispatch => {
    try {
        const deleteResponse = await ApiService.deleteFileLocker(token, itemId, {key: fileType, file_name: fileName})
        const { data } = deleteResponse
        dispatch(setFileLocker(data))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const getFileLockerRequest = (token, itemId) => async dispatch => {
    try {
        const response = await ApiService.getFileLocker(token, itemId)
        const { data } = response
        dispatch(setFileLocker(data))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const uploadFileLocker = (token, itemId, fileData, fileType, fileName) => async dispatch => {
    try {
        const s3UrlResponse = await ApiService.postFileLocker(token, itemId, {key: fileType, file_name: fileName})
        const s3UrlData  = s3UrlResponse.data
        const { url, fields } = s3UrlData
        const uploadFormData = new FormData()

        Object.keys(fields).forEach(key => {
            uploadFormData.append(key, fields[key]);
        });

        uploadFormData.append("file", fileData);

        const s3UploadResponse = await ApiService.postToS3(url, uploadFormData)
        dispatch(getFileLockerRequest, itemId)
        return s3UploadResponse.data

    } catch(error) {
        console.warn(error)
    }
}

export const setLoginUrlCache = (urlCacheData) => {
    return {
        type: SET_LOGIN_URL_CACHE,
        urlCacheData
    }
}

export const getThumbnailRequest = (token, itemId) => async dispatch => {
    try {
        const response = await ApiService.getThumbnail(token, itemId)
        const { data } = response
        return data

    } catch(error) {
        console.warn(error)
    }
}


export const editThumbnailRequest = (token, thumbnailId, thumbnailData) => async dispatch => {
    try {
        const response = await ApiService.patchThumbnail(token, thumbnailId, thumbnailData)
        const { data } = response
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const createThumbnailRequest = (token, thumbnailData) => async dispatch => {
    try {
        const response = await ApiService.postThumbnail(token, thumbnailData)
        const { data } = response
        return data

    } catch(error) {
        console.warn(error)
    }
}
