import React, { Component } from 'react'
import { Button, Typography, Spin } from 'antd'
import { withRouter } from "react-router"
import { 
    LOGIN_ENDPOINT, 
} from '../shared/constants'
import { LoginOutlined } from '@ant-design/icons';
import Logo from '../assets/logo.png'
import { Company } from './'

const { Title } = Typography;

class Home extends Component {
  
    render() {
        const {companies, users, auth} = this.props
        const { token, user } = auth

        return <>
            {token ?
                user ?
                <Company auth={auth} users={users} companies={companies}/>
                : <Spin />
            : 
            <div className='login-no-auth'>
                <div className='title-button'>
                    <div className='logo-img'>
                        <img style={{width:200}} alt='br-logo' src={Logo} />
                    </div> 
                    <Title className='login-title'>Content Managment System</Title>
                    <div className='button-div'>
                        <Button style={{backgroundColor: '#04a7b9', borderColor: '#04a7b9'}} icon={<LoginOutlined />} onClick={() => {
                            window.location.href = LOGIN_ENDPOINT
                            }
                        } type='primary'>Sign In
                        </Button>                    
                    </div>
                </div>
            </div>
            }
        </>
        }
    }

    export default withRouter(Home)