import React, { Component } from 'react'
import {
    Home,
    ModelInfo,
    ModelSelect,
    QRViewer,
} from '../../screens'
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom'
import { withRouter } from "react-router";
import { 
    HOME_URL,
    MODEL_INFO_URL,
    MODEL_SELECT_URL,
    QR_VIEW_URL,
} from '../constants'

const HomeRedirect = ({ location }) => <Redirect to={HOME_URL} />

class ContentArea extends Component {
    render() {
        const {companies, users, auth} = this.props

        return (
            <div className={'contentarea'} onMouseMove={this.handleMouseMove}>
                <Switch>
                    <Route render={(props) => <ModelInfo {...props} token={auth.token} user={auth.user}/>} path={MODEL_INFO_URL} />
                    <Route render={(props) => <ModelSelect {...props} token={auth.token} user={auth.user}/>} path={MODEL_SELECT_URL} />
                    <Route render={(props) => <QRViewer {...props} />} path={QR_VIEW_URL} />
                    <Route render={(props) => <Home {...props} auth={auth} users={users} companies={companies}/>} path={HOME_URL} />
                    <Route component={HomeRedirect} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(ContentArea)