import React, { Component } from 'react'
import { Menu } from 'antd';
import { setOrder } from '../../features/companies/companiesActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'

const { SubMenu } = Menu;

class SideMenu extends Component {
  handleClick = e => {
    const orderId = e.key
    const { companies, setOrder } = this.props
    const { companyDetail } = companies

    companyDetail.projects.forEach( project => {
        const targetOrder = _.filter(project.orders, obj => obj.id == orderId)
        if (targetOrder.length) {
            setOrder(targetOrder[0])
        }
    })
  };

  getOrders(project) {
      const orderItems = []
      project.orders.forEach(order => {
        orderItems.push(
            <Menu.Item key={order.id}>
                {order.name}
            </Menu.Item>
        )
      })
      return orderItems
  }

  getMenuItems() {
      const { projects } = this.props
      const menuItems = []

      projects.forEach(project => {
          menuItems.push(
              <SubMenu
                key={project.name}
                title={project.name}
              >
                  {this.getOrders(project)}
              </SubMenu>
          )
      })

      return menuItems

  }

  render() {
    return (
    <Menu
        onClick={this.handleClick}
        mode="inline"
        style={{ width: 256, borderTop: '1px solid #F5F5F5', borderBottom: '1px solid #F5F5F5', borderLeft: '1px solid #F5F5F5' }}
    >
        {this.getMenuItems()}
    </Menu>
    );
  }
}

const mapStateToProps = ({ companies }) => {
    return { 
      companies
    }
  }
  
  export default connect (mapStateToProps, {
    setOrder,
  }) (withRouter(SideMenu))