import {
    SET_USERS,
    UPDATE_USER,
    ADD_USER,
} from './usersConstants'
import produce from 'immer'

const INITIAL_STATE = {}

export default produce ((draft = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USERS:
            const { users } = action
            draft['users'] = users
            break

        case UPDATE_USER:
            const { userId, userData } = action
            draft['users'][userId] = userData
            break

        case ADD_USER:
            const { user } = action
            draft['users'][user.id] = user
            break
    
        default:
            break
        }
    
    return draft
})