import React, { Component } from 'react'
import { Spin } from 'antd'
import { withRouter } from "react-router"

import { AdminCompany, ClientCompany } from './'

class Company extends Component {
  
    render() {
        const { companies, users, auth } = this.props
        const { token, user } = auth

        if ( ! auth || ! companies || ! users ) {
            return <Spin />
        }
        return <>
            {
                user.admin 
                ? <AdminCompany token={token} />
                : <ClientCompany token={token} companies={companies} />
            }
        </>
    }
}

export default (withRouter(Company))