import axios from 'axios'
import { notification } from 'antd'
import { BACKEND_URL  } from '../shared/constants'
import {store} from '../App'
import {setLoginUrlCache} from '../features/companies/companiesActions'
import {setToken} from '../features/auth/authActions'

const GET_METHOD = 'get'
const POST_METHOD = 'post'
const PATCH_METHOD = 'patch'
const DELETE_METHOD = 'delete'

async function clearStorageAsync() {
    await null;
    return localStorage.clear();
}

async function redirectAsync(){
    const {urlCacheData} = store.getState().companies;
    await clearStorageAsync();
    store.dispatch(setToken(null));

    if (urlCacheData != null) {
        localStorage.setItem('itemId', urlCacheData.item);
        localStorage.setItem('companyId', urlCacheData.company);
    }
    store.dispatch(setLoginUrlCache(urlCacheData));
    
    window.location.href = window.location.origin;
}

axios.interceptors.response.use(
    res => res,
    async (err) => {
        if (err.response.status === 404) {
            notification.open({message: 'Not Found', description:err.response.data.message})
        } else if (err.response.status === 400) {
            notification.error({message: 'Bad Request', description: err.response.data.message})
        } else if (err.response.status === 401) {
            notification.error({message: 'Authentication Needed', description: `${err.response.data.message}. Try logging in again.`})
            await redirectAsync();
        } else if (err.response.status === 403) {
            notification.error({message: 'Bad Authentication', description: `${err.response.data.message}. Try logging in again.`})
            await redirectAsync();
        } else if (err.response.status === 409) {
            notification.error({message: 'Conflict', description: err.response.data.message})
        } else {
            console.log(err)
            throw err;
        }
    }
  );
  
class ApiService {
    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }

    postRefresh = async (token, refresh) => {
        const response = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/refresh`,
            headers: {'Authorization': `Bearer ${token}`},
            data: { refresh },
        })
        return response
    }

    getMe = async (token) => {
        const response = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/me`,
            headers: {'Authorization': `Bearer ${token}`},
        })
        return response
    }

    postUser = async (token, user) => {
        const response = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/users`,
            headers: {'Authorization': `Bearer ${token}`},
            data: { email: user.email },
        })
        return response
    }

    getUsers = async (token) => {
        const response = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/users`,
            headers: {'Authorization': `Bearer ${token}`},
        })
        return response
    }

    getItemStatuses = async (token) => {
        const response = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/item_statuses`,
            headers: {'Authorization': `Bearer ${token}`},
        })
        return response
    }

    getCompanies = async (token) => {
        const response = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/companies`,
            headers: {'Authorization': `Bearer ${token}`},
        })
        return response
    }

    getCompany = async (token, companyId) => {
        const response = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/companies/${companyId}`,
            headers: {'Authorization': `Bearer ${token}`},
        })
        return response
    }

    patchCompany = async (token, companyId, companyData) => {
        const response = await axios({
            method: PATCH_METHOD,
            url: `${BACKEND_URL}/companies/${companyId}`,
            headers: {'Authorization': `Bearer ${token}`},
            data: companyData,
        })
        return response
    }

    getItem = async (token, itemId) => {
        const response = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/items/${itemId}`,
            headers: {'Authorization': `Bearer ${token}`},
        })
        return response
    }

    patchItem = async (token, itemId, itemData) => {
        const response = await axios({
            method: PATCH_METHOD,
            url: `${BACKEND_URL}/items/${itemId}`,
            headers: {'Authorization': `Bearer ${token}`},
            data: itemData,
        })
        return response
    }

    patchItemVersion = async (token, itemVersionId, itemVersionData) => {
        const response = await axios({
            method: PATCH_METHOD,
            url: `${BACKEND_URL}/item_versions/${itemVersionId}`,
            headers: {'Authorization': `Bearer ${token}`},
            data: itemVersionData,
        })
        return response
    }

    postCompany = async (token, companyData) => {
        const response = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/companies`,
            headers: {'Authorization': `Bearer ${token}`},
            data: companyData,
        })
        return response
    }

    postOrder = async (token, orderData) => {
        const response = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/orders`,
            headers: {'Authorization': `Bearer ${token}`},
            data: orderData,
        })
        return response
    }

    postProject = async (token, projectData) => {
        const response = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/projects`,
            headers: {'Authorization': `Bearer ${token}`},
            data: projectData,
        })
        return response
    }

    postItemVersion = async (token, versionData) => {
        const response = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/item_versions`,
            headers: {'Authorization': `Bearer ${token}`},
            data: versionData,
        })
        return response
    }

    postItemNote = async (token, noteData) => {
        const response = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/item_notes`,
            headers: {'Authorization': `Bearer ${token}`},
            data: noteData,
        })
        return response
    }

    postItem = async (token, itemData) => {
        const response = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/items`,
            headers: {'Authorization': `Bearer ${token}`},
            data: itemData,
        })
        return response
    }

    patchItem = async (token, itemData) => {
        const itemId = itemData.id
        delete itemData.id

        const response = await axios({
            method: PATCH_METHOD,
            url: `${BACKEND_URL}/items/${itemId}`,
            headers: {'Authorization': `Bearer ${token}`},
            data: itemData,
        })
        return response
    }

    deleteItem = async (token, itemId) => {
        const response = await axios({
            method: DELETE_METHOD,
            url: `${BACKEND_URL}/items/${itemId}`,
            headers: {'Authorization': `Bearer ${token}`},
        })
        return response
    }

    postUserCompany = async (token, userId, companyId) => {
        const response = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/user_companies/${userId}/${companyId}`,
            headers: {'Authorization': `Bearer ${token}`},
        })
        return response
    }

    getThumbnail = async (token, itemId) => {
        const response = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/item_thumbnails/${itemId}`,
            headers: {'Authorization': `Bearer ${token}`},
        })
        return response
    }

    patchThumbnail = async (token, thumbnailId, data) => {
        const response = await axios({
            method: PATCH_METHOD,
            url: `${BACKEND_URL}/item_thumbnails/${thumbnailId}`,
            headers: {'Authorization': `Bearer ${token}`},
            data
        })
        return response
    }

    postThumbnail = async (token, data) => {
        const response = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/item_thumbnails`,
            headers: {'Authorization': `Bearer ${token}`},
            data
        })
        return response
    }

    deleteUserCompany = async (token, userId, companyId) => {
        const response = await axios({
            method: DELETE_METHOD,
            url: `${BACKEND_URL}/user_companies/${userId}/${companyId}`,
            headers: {'Authorization': `Bearer ${token}`},
        })
        return response
    }

    postFileLocker = async (token,  itemId, fileLockerData) => {
        const response = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/file_locker/${itemId}`,
            headers: {'Authorization': `Bearer ${token}`},
            data: fileLockerData,
        })
        return response
    }

    getFileLocker = async (token, itemId) => {
        const response = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/file_locker/${itemId}`,
            headers: {'Authorization': `Bearer ${token}`},
        })
        return response
    }

    deleteFileLocker = async (token, itemId, fileLockerData) => {
        const response = await axios({
            method: DELETE_METHOD,
            url: `${BACKEND_URL}/file_locker/${itemId}`,
            headers: {'Authorization': `Bearer ${token}`},
            data: fileLockerData,
        })
        return response
    }

    postToS3 = async (url, formData) => {
        const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        return response
    }

    postAnnotation = async (token, annotationData) => {
        const response = await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/annotations`,
            headers: {'Authorization': `Bearer ${token}`},
            data: annotationData,
        })
        return response
    }
}
export default new ApiService()