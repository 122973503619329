import { 
    UPDATE_USER,
    SET_USERS,
    ADD_USER,
} from './usersConstants'

import ApiService from '../apiService'
import _ from 'lodash'

export const setUsers = (users) => {
    return {
        type: SET_USERS	,
        users,
    }
}

export const updateUser = (userId, userData) => {
    return {
        type: UPDATE_USER,
        userId,
        userData,
    }
}

export const addUser = (user) => {
    return {
        type: ADD_USER,
        user,
    }
}

export const createUser = (token, user) => async dispatch => {
    try {
        const response = await ApiService.postUser(token, user)
        const { data } = response

        dispatch(addUser(data))
        return data

    } catch(error) {
        console.warn(error)
    }
}

export const getAllUsers = (token) => async dispatch => {
    try {
        const response = await ApiService.getUsers(token)
        const { data } = response
        const users = {}

        data.forEach(element => {
            users[element.id] = element
        })

        dispatch(setUsers(users))
        return users

    } catch(error) {
        console.warn(error)
    }
}
