import {
    SET_COMPANIES,
    UPDATE_COMPANY,
    SET_COMPANY_DETAIL,
    SET_ORDER,
    CREATE_ORDER,
    CREATE_PROJECT,
    CREATE_COMPANY,
    SET_ITEM,
    SET_STATUSES,
    SET_FILE_LOCKER,
    SET_ACTIVE_COMMENT_ID,
    SET_HOTSPOTS,
    ADD_HOTSPOT,
    REMOVE_HOTSPOT,
    SELECT_HOTSPOT,
    SET_LOGIN_URL_CACHE
} from './companiesConstants'
import produce from 'immer'
import _ from 'lodash'

const INITIAL_STATE = {}

export default produce ((draft = INITIAL_STATE, action) => {
    let modelViewer = document.getElementById('threeDModel');
    switch (action.type) {
        case SET_COMPANIES:
            const { companies } = action
            draft['companies'] = companies
            break

        case SET_STATUSES:
            const { statuses } = action
            draft['itemStatuses'] = statuses
            break

        case UPDATE_COMPANY:
            const { companyId, companyData } = action
            draft['companies'][companyId] = companyData
            break
        
        case SET_COMPANY_DETAIL:
            const { companyDetail } = action
            draft['companyDetail'] = companyDetail
            break

        case SET_ORDER:
            const { orderData } = action
            draft['orderData'] = orderData
            break

        case SET_ITEM:
            const { itemData } = action
            draft['itemData'] = itemData
            break

        case CREATE_ORDER:
            const { newOrderData } = action
            const endProjects = []

            draft.companyDetail.projects.forEach(project => {
                if (project.id != newOrderData.project_id) {
                    endProjects.push(project)
                } else {
                    project.orders.push(newOrderData)
                    endProjects.push(project)
                }
            })
            draft.companyDetail.projects = endProjects
            break

        case CREATE_PROJECT:
            const { newProjectData } = action
            draft.companyDetail.projects.push(newProjectData)
            break
            
        case CREATE_COMPANY:
            const { newCompanyData } = action
            draft.companies[newCompanyData.id] = newCompanyData
            break

        case SET_FILE_LOCKER:
            const { fileLockerData } = action
            draft['fileLockerData'] = fileLockerData
            break

        case SET_ACTIVE_COMMENT_ID:
            const { activeCommentId } = action
            draft['activeCommentId'] = activeCommentId
            break

        case SET_HOTSPOTS:
            const { allHotSpots } = action
            draft['allHotSpots'] = allHotSpots
            break

        case ADD_HOTSPOT:
            const { domHotSpot } = action
            let draftHotSpots = []

            if (draft['allHotSpots']) {
                draft['allHotSpots'].forEach(obj => {
                    draftHotSpots.push(obj)
                })
            }
            modelViewer.appendChild(domHotSpot)

            draftHotSpots.push(domHotSpot)

            draft['allHotSpots'] = draftHotSpots
            break

        case REMOVE_HOTSPOT:
            const { oldHotSpot } = action
            try {
                modelViewer.removeChild(oldHotSpot)
            } catch {
                console.log('err')
            }
            draft['allHotSpots'] = _.filter(draft['allHotSpots'], hotspot => {
                return (! (hotspot.slot == oldHotSpot.slot && hotspot.classList.value == oldHotSpot.classList.value))
            })
            break

        case SELECT_HOTSPOT:
            const { selectHotSpot } = action
            draft['selectHotSpot'] = selectHotSpot
            break                                                              

        case SET_LOGIN_URL_CACHE:
            const { urlCacheData } = action
            draft['urlCacheData'] = urlCacheData
            break
        default:
            break
        }
    
    return draft
})