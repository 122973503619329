import React, { Component } from 'react'
import { connect } from 'react-redux'
import { 
    addUserToCompany, 
    removeUserFromCompany,
    getCompanyRequest,
    createCompany,
    setCompanyDetail,
} from '../features/companies/companiesActions'
import {
    createUser
} from '../features/users/usersActions'
import { 
    Select, 
    Table, 
    Button,
    Typography,
    Modal,
    Form,
    Input,
 } from 'antd'
import _ from 'lodash'
import { MODEL_SELECT_URL } from '../shared/constants'
import { withRouter } from "react-router"
import { PlusOutlined, CloseOutlined, RightOutlined } from '@ant-design/icons'

const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

class AdminCompany extends Component {
    state = {
        showCompanyModal: false,
        showUserModal: false
    }
    companyFormRef = React.createRef();
    userFormRef = React.createRef();

    componentDidMount(){
        const { setCompanyDetail } = this.props
        setCompanyDetail(null)
    }

    onFinishFailed = () => {
        console.log('Bad input!')
    }

    onCompanyFinish = () => {
        const { createCompany, token } = this.props
        const data = this.companyFormRef.current.getFieldsValue()
        createCompany(token, data)
        this.setState({showCompanyModal: false})
    }

    onUserFinish = () => {
        const { createUser, token } = this.props
        const data = this.userFormRef.current.getFieldsValue()
        createUser(token, data)
        this.setState({showUserModal: false})
    }

    renderUsers = (record) => {
        const { users } = this.props
        const { Option } = Select
        const options = []

        if (users.users) {
            Object.values(users.users || []).forEach(user =>  {
                options.push(<Option key={user.email} value={user.email}>{user.email}</Option>)
            })
        }

        return <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select users"
            value={_.map( record.users, 'email')}
            onSelect={(e) => this.handleUserAdd(e, record)}
            onDeselect={(e) => this.handleUserDelete(e, record)}
        >
            {options}
        </Select>
    }
    
    handleUserAdd(e, company) {
        const { users, token, addUserToCompany } = this.props
        const targetUser = _.map(users.users, (data => data)).find( (user) => {
            return user.email === e
        })
        addUserToCompany(token, targetUser.id, company.id)
    }

    handleUserDelete(e, company) {
        const { users, token, removeUserFromCompany } = this.props
        const targetUser = _.map(users.users, (data => data)).find( (user) => {
            return user.email === e
        })      
       removeUserFromCompany(token, targetUser.id, company.id)
    }

    render() {
        const columns = [
            {
                title: 'Company Name',
                dataIndex: 'name',
                key: 'name',
                disabled: false,
            },
            {
                title: 'Users',
                key: 'users',
                disabled: true,
                render: (text, record ) => this.renderUsers(record),
            },
            {
                title: 'View',
                key: 'id',
                disabled: true,
                render: (text, record ) => {
                    return <Button onClick={() => {
                        const { token, history, getCompanyRequest } = this.props
                        getCompanyRequest(token, record.id)
                        history.push(MODEL_SELECT_URL)
                    }
                    }
                    icon={<RightOutlined />}
                    >Go</Button>
                },
            },
        ]
        const { showCompanyModal, showUserModal } = this.state
        const { companies } = this.props
        const { Title } = Typography

        return <>
            <Title level={4}>Companies</Title>
            <Button style={{marginBottom: 15}} icon={<PlusOutlined />} onClick={() => this.setState({showCompanyModal: true})}>Create Company</Button>
            <Button style={{marginBottom: 15, marginLeft: 15 }} icon={<PlusOutlined />} onClick={() => this.setState({showUserModal: true})}>Create User</Button>
            <Table   
                rowKey={'id'}
                columns={columns} 
                dataSource={_.map(companies.companies, obj => obj)}
            />
            <Modal
                title={'Create Company'}
                visible={ showCompanyModal }
                onOk={this.onCompanyFinish}
                onCancel={() => this.setState({showCompanyModal: false})}
                destroyOnClose={true}
                cancelText='Cancel'
                okButtonProps={{ icon: <PlusOutlined /> }}
                cancelButtonProps={{ icon: <CloseOutlined /> }}
                okText='Create'
            >
                <Form
                    {...formLayout}
                    name="company"
                    onFinish={this.onCompanyFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.companyFormRef}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input company name - must be unique!' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Create User'}
                visible={ showUserModal }
                onOk={this.onUserFinish}
                onCancel={() => this.setState({showUserModal: false})}
                destroyOnClose={true}
                cancelText='Cancel'
                okButtonProps={{ icon: <PlusOutlined /> }}
                cancelButtonProps={{ icon: <CloseOutlined /> }}
                okText='Create'
            >
                <Form
                    {...formLayout}
                    name="user"
                    onFinish={this.onUserFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.userFormRef}
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input user email - must be unique!' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    }
}

const mapStateToProps = ( { users, companies }) => {
    return { 
        users, 
        companies
    }
}

export default connect (mapStateToProps, {
    getCompanyRequest,
    removeUserFromCompany,
    addUserToCompany,
    createCompany,
    createUser,
    setCompanyDetail,
}) (withRouter(AdminCompany))