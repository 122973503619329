import React, { Component } from 'react'
import { withRouter } from "react-router"

var modelElement = null;
var expandedBtnId = null;
const FULLSCREEN_EXPAND_BTN_CLASS = 'fullscreen-btn'

//determine ipad pro
function isIpadOS() {
  return navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform);
}

//Determine OS
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if ((/iPad|iPhone|iPod/.test(userAgent) || isIpadOS()) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

//determine whether to invoke AR
function launchIOSQuickLookAR(usdzSrc) {
  const anchor = document.createElement('a');
  anchor.setAttribute('rel', 'ar');
  anchor.appendChild(document.createElement('img'));
  anchor.setAttribute('href', usdzSrc);
  anchor.click();
}
function launchAndroidAR(glb) {
  const glbHref = `intent://arvr.google.com/scene-viewer/1.0?file=${glb}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`
  const anchor = document.createElement('a');
  anchor.setAttribute('href', glbHref);
  anchor.click();
}

function toggleFullscreen(id, buttonId) {
  expandedBtnId = buttonId;
  modelElement = document.getElementById(id);
  if(modelElement.getAttribute("expanded") == "true") {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
  } else {
    if (modelElement.requestFullscreen) {
      modelElement.requestFullscreen();
    } else if (modelElement.mozRequestFullScreen) { /* Firefox */
      modelElement.mozRequestFullScreen();
    } else if (modelElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      modelElement.webkitRequestFullscreen();
    } else if (modelElement.msRequestFullscreen) { /* IE/Edge */
      modelElement.msRequestFullscreen();
    }
  }
}

//fullscreen change handler
function changeHandler() {
    //change style of the button 
    document.getElementById(expandedBtnId).classList.toggle(FULLSCREEN_EXPAND_BTN_CLASS);
    //change the button state
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      if (modelElement.getAttribute('expanded') == "true") {
        modelElement.setAttribute('expanded','false');

        //reset button
        modelElement = null;
        expandedBtnId = null;
    }
    } else{
        modelElement.setAttribute('expanded','true');
    }
} 

class QRViewer extends Component {

    componentDidMount() {
        if (getMobileOperatingSystem() == "unknown") {
          } else {
            var urlParams = new URLSearchParams(window.top.location.search);
            if(urlParams.has("showAR") && (urlParams.has("usdzModel") || urlParams.has("glbModel")) && urlParams.get("showAR")) {
          
              if(getMobileOperatingSystem() == "iOS") {
                launchIOSQuickLookAR(urlParams.get("usdzModel"))
              } 
              if(getMobileOperatingSystem() == "Android") {
                launchAndroidAR(urlParams.get("glbModel"))
              }
            }
          }
          
          //exit fullscreen listener
          document.addEventListener('fullscreenchange', changeHandler);
          document.addEventListener('webkitfullscreenchange', changeHandler);
          document.addEventListener('mozfullscreenchange', changeHandler);
          document.addEventListener('MSFullscreenChange', changeHandler); 
          
          //update model-viewer's shadows
          // $('model-viewer').each( function() { 
          //   this.shadowIntensity = 1;
          //   this.shadowSoftness = 1;
          //   })
    }

    render() {

        return <>
        </>
    }
}
export default withRouter(QRViewer)